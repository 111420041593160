import { navigate } from "gatsby"
import { isBrowser } from "../../services/helper"

const Tools = () => {
    if(isBrowser()) {
        navigate(`/tools/hubspot-webflow-form-tool`, { replace: true})
    }
    return null
}

export default Tools